@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/base/colors";
@import "../../assets/scss/base/typography";

.skills {
    margin-bottom: $mb-2-5;
    padding: 2rem;
    &__container {
        row-gap: 0;
        justify-content: center;
        align-items: center;
    }

    &__content {
        margin-bottom: $mb-2-5;
    }

    &__header {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: $mb-1;
    }

    &__icon,
    &__arrow {
        font-size: 2rem;
        color: $primary;
    }

    &__icon {
        margin-right: $mb-0-75;
    }

    &__title {
        font-size: $fs-h3;
    }

    &__subtitle {
        font-size: $fs-sm;
        color: $textColorLight;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__data {
        margin-right: 1rem;
        padding: 0.75rem;
        border-radius: 0.5rem;
        background-color: $primaryLighter;
        margin-bottom: $mb-0-5;
    }

    &__arrow {
        margin-left: auto;
    }

    &__name {
        font-size: $fs-md;
        font-weight: $fw-md;
    }

    &__close &__list {
        height: 0;
        overflow: hidden;
    }

    &__open &__list {
        height: max-content;
        margin-bottom: $mb-2-5;
    }

    &__open &__arrow {
        transform: rotate(-180deg);
    }


}