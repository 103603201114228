@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/base/colors";
@import "../../assets/scss/base/typography";


.home {
    min-height: 100vh;

    display: grid;
    align-items: center;

    &__container {
        gap: 1rem;
    }

    &__content {
        grid-template-columns: .5fr 3fr;
        padding-top: 3.5rem;
        align-items: center;
    }

    &__blob {
        width: 200px;
        fill: $primary;

        &-img {
            width: 170px;
        }
    }

    &__data {
        grid-column: 1/3;
    }

    h1 {
        transition-delay: 100ms;
        color: $primary;
    }

    &__title {
        font-size: clamp(40px, 8vw, 80px);
        transition-delay: 200ms;
    }

    &__subtitle {
        font-size: clamp(30px, 6vw, 60px);
        ;
        color: $textColorLight;
        font-weight: $fw-md;
        margin-bottom: $mb-0-75;
        transition-delay: 300ms;
    }

    &__description {
        margin-top: $mb-1-5;
        margin-bottom: $mb-2;
        transition-delay: 400ms;
    }

    &__scroll {

        // display: none;
        &-button {
            color: $primary;
            transition: .3s;

            &:hover {
                transform: translateY(.25rem);
            }
        }

        &-mouse {
            font-size: 2rem;
        }

        &-name {
            font-size: $fs-sm;
            color: $titleColor;
            font-weight: $fw-md;
            margin-right: $mb-0-25;
        }

        &-arrow {
            font-size: 1.25rem;
        }
    }
}

@media screen and (max-width: 350px) {

    .home {

        &__content {
            grid-template-columns: .25fr 3fr;
        }

        &__blob {
            width: 180px;
        }
    }
}

@media screen and (min-width: 568px) {

    .home {

        &__container {
            row-gap: 2rem !important;
        }

        &__content {
            grid-template-columns: max-content 1fr 1fr;
        }

        &__data {
            grid-column: initial;
        }

        &__img {
            order: 1;
            justify-self: center;
        }
    }
}

@media screen and (min-width: 768px) {
    .home {
        &__container {
            row-gap: 5rem !important;
        }

        &__content {
            padding-top: 5.5rem;
            column-gap: 2rem;
        }

        &__blob {
            width: 270px;
        }

        &__scroll {
            display: block;

            // &-button {
            //     margin-left: 3rem;
            // }
        }
    }
}

@media screen and (min-width: 1024px) {

    .home {
        margin-left: 2rem;
        &__blob {
            width: 320px;
        }

        &__social {
            transform: translateX(-6rem);
        }
    }


}