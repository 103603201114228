@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/base/colors";
@import "../../assets/scss/base/typography";


.work {
    height: auto;
    &__list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__card {
        background-color: $primaryLighter;
        border-radius: .5rem;
        margin-bottom: 2rem;
        padding: 1rem;
        opacity: 0.7;
        // height: 30vw;

        .work__link {
            display: none;
        }

        &:hover {
            transition: opacity 0.75s;
            -webkit-transition: opacity 0.75s;
            opacity: 1;

            .work__link {
                position: absolute;
                bottom: 10%;
                right: 2%;
                cursor: pointer;
                font-size: 2rem;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background-color: $primary;
                display: flex;
                justify-content: center;
                align-items: center;
                animation: fadeInAnimation ease 0.75s;animation-iteration-count: 1;
                animation-fill-mode: forwards;

                &:hover {
                    transform: scale(1.1);
                }

                i {
                    padding: 0.5rem;
                    color: $titleColor;
                }

            }
        }
    }

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    &__image {
        position: relative;
        padding-bottom: .75rem;
        border-radius: 3px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;

        }
    }

    &__name {
        color: $titleColor;
        font-weight: $fw-sb;
        font-size: 1.125rem;
        cursor: pointer;

        a {
            color: inherit;
        }
    }

    &__skills {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0.75rem;
        margin-top: 0.75rem;

        &-badge {
            border: 1px solid $primary;
            font-size: .75rem;
            color: $primary;
            font-weight: $fw-sb;
            margin-right: .75rem;
            border-radius: 3px;
            padding: 2px 4px;
            margin-bottom: 0.75rem;
        }
    }

    &__description {
        color: $textColorLight;
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 768px) {
    .work {
        &__list {
            flex-direction: row;
            justify-content: space-between;
        }

        &__card {
            flex: 0 0 47%;
            min-height: 25vw;
            margin-bottom: 1.5rem;

        }

        &__image {
            height: calc(25vw - .75rem);
        }

       
    }
}

@media screen and (min-width: 1024px) {
    work__overflow {
        height: 120px;
        overflow-y: auto;
    }
}