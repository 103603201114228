@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/base/colors";
@import "../../assets/scss/base/typography";

.header {
    width: 100%;
    color: red;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: $z-fixed;
    background-color: $bodyColor;
}

.nav {
    height: $headerHeight;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__logo {
        margin-left: 1rem;
        color: $primary;
        font-weight: $fw-md;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        border: 2px solid $primary;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: $fs-h2;
            font-weight: $fw-sb;
            margin: auto;
        }
        &:hover {
            color: $primary;
            background-color: $primaryLighter;
        }
    }
    
    &__toggle {
        color: $titleColor;
        font-weight: $fw-md;
        font-size: 1.1rem;
        cursor: pointer;
        &:hover {
            color: $primary;
        }
    }

    &__list {
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: $fs-sm;
        color: $titleColor;
        font-weight: $fw-md;
        
        &:hover {
            color: $primary
        }

    }

    &__icon {
        font-size: 1.2rem;
    }

    &__close {
        position: absolute;
        right: 1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: $primary;

        &:hover {
            color: $primaryDark;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
    }

}

.change-theme {
    font-size: $fs-h3;
    color: $titleColor;
    margin-right: $mb-1;
    cursor: pointer;

    &:hover {
        color: $primary;
    }
}

.show-menu {
    bottom: 0 !important;
}

@media screen and (max-width: 767px) {
    .nav__menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width:100%;
        background-color: $bodyColor;
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, .15);
        border-radius:  1.5rem 1.5rem 0 0;
        transition: 0.3s;
    }
}

@media screen and (min-width: 768px) {
    .header {
        top: 0;
        bottom: initial;
        padding: 0 1rem;
    }

    .nav {
        height: 4.5rem;
        column-gap: 1rem;
        padding-top: 1rem;

        &__icon,
        &__close,
        &__toggle {
            display: none;
        }

        &__list {
            display: flex !important;
            column-gap: 2rem !important;
        }

        &__menu {
            margin-left: auto;
        }
    }

    .change-theme {
        margin: 0;
    }
}

@media screen and (max-width: 350px) {

    .nav {

        &__menu {
            padding: 2rem .25rem 4rem;
        }

        &__list {
            column-gap: 0;
        }

    }
}