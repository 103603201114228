.container {
    margin-left: $mb-1-5;
    margin-right: $mb-1-5;
}

.grid {
    display: grid;
    gap: 1.5rem;
}

@media screen and (max-width: 350px) {
    .container {
        margin-left: $mb-1;
        margin-right: $mb-1;
    }
}
