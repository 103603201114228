@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/base/colors";
@import "../../assets/scss/base/typography";

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    min-height: 70px;
    margin-bottom: 3.5rem;

    &__social {
        &-icon {
            font-size: 1.25rem;
            color: $primary;

            &:hover {
                color: $primaryDark;
            }
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            margin: 0px;
            padding: 0px;
        }

        li {
            padding: 1rem;

            &:hover {
                transform: translateY(-3px);
            }
        }
    }

    &__location {
        text-align: center;
        padding: 1rem;

        div {
            margin-bottom: .5rem;
        }
    }
}

@media screen and (min-width: 768px) {

    .footer {
        &__social {
            ul {
                display: none;
            }
        }
    }
}