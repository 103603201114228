@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/base/colors";
@import "../../assets/scss/base/typography";


.about {
    justify-content: center;
    align-items: center;

    &__img {
        width: 200px;
        border-radius: 0.5rem;
        justify-self: center;
        align-self: center;
    }

    &__blob {
        width: 220px;
        fill: $primary;
        &-img {
            width: 215px;
        }
    }

    &__description {
        margin-bottom: $mb-2-5;
    }

    &__info {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: $mb-2-5;
        &-container {
            padding: 0.5rem;
            // background-color: $primaryLighter;
            // border-radius: 5px;
        }

        &-name {
            font-size: $fs-sm;
        }

        &-title {
            font-size: $fs-h2;
            font-weight: $fw-sb;
            color: $titleColor;
        }

        &-title, &-name {
            display: block;
            text-align: center;
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
    }

}

@media screen and (min-width: 768px) {
    .about {
        &__container {
            width: 605px;
            margin: auto;
        }
    }
}

@media screen and (min-width: 1024px) {
    .about {
        &__container {
            width: 800px;
            margin: auto;
        }
    }
}