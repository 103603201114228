@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/base/colors";
@import "../../assets/scss/base/typography";

.experience {
    &__container {
        grid-auto-flow: column;
        grid-template: max-content auto;
        // grid-template-rows: max-content auto;
    }

    &__tabList {
        margin: 0;
        padding: 0;
    }

    &__button {
        transition: $transition;
        display: flex;
        align-items: center;
        width: 100%;
        height: 42px;
        padding: 0px 20px 2px;
        border: 0;
        border-left: 3px solid $primaryLight;
        background-color: transparent;
        color: $titleColor;
        font-size: $fs-md;
        text-align: left;
        white-space: nowrap;
        border-radius: 0px;
        cursor: pointer;

        &-active {
            color: $primary;
            border-left: 3px solid $primary;
            background-color: $primaryLighter;
        }

        &:hover {
            color: $primary;
            background-color: $primaryLighter;
        }
    }

    &__tabPanels {
        margin-left: 1.25rem;
        padding: 10px 5px;
    }

    &__company {
        font-size: $fs-h2;

        &-link {
            display: inline-block;
            transition: $transition;
            color: $primary;
            text-decoration: none;
            text-decoration-skip-ink: auto;
            position: relative;


            &::after {
                content: "";
                display: block;
                width: 0px;
                height: 1px;
                position: relative;
                top: 1px;
                bottom: 0.37em;
                background-color: $primary;
                transition: $transition;
                opacity: 0.5;
            }

            &:hover {
                color: $primary;
                outline: 0px;

                &::after {
                    width: 100%;
                }
            }
        }
    }

    &__duration {
        font-size: $fs-xs;
        color: $textColorLight;
        margin-bottom: $fs-h3;
    }

    &__reponsibilities {
        list-style: none;
        margin: 0px;
        padding: 0px;
        font-size: 1rem;

        li {
            position: relative;
            padding-left: 1.25rem;
            margin-bottom: 1rem;

            &::before {
                content: "▹";
                position: absolute;
                left: 0px;
                color: $primary;
            }
        }
    }
}

@media screen and (max-width: 568px) {
    .experience {

        &__container {
            grid-auto-flow: row;
            grid-template: max-content auto;
        }
    
        &__tabList {
            display: flex;
            overflow-x: auto;
        }

        &__button {
            border-left: 0;
            &-active {
                color: $primary;
                border-bottom: 4px solid $primary;
                background-color: $primaryLighter;
            }
        }
    }

}

@media screen and (min-width: 768px) {
    .experience {
        &__container {
            width: 605px;
            margin: auto;
        }
    }
}

@media screen and (min-width: 1024px) {
    .experience {
        &__container {
            width: 750px;
            margin: auto;
        }
    }
}