.section {
    padding: 2rem 0 4rem;

    &__title {
        font-size: clamp(24px, 5vw, $fs-h1);

        // &::before {
        //     content: "";
        //     display: block;
        //     position: relative;
        //     top: -5px;
        //     width: 300px;
        //     height: 1px;
        //     margin-left: 20px;
        //     background-color: $primaryLight;
        // }

        // &::after {
        //     content: "";
        //     display: block;
        //     position: relative;
        //     top: -5px;
        //     width: 300px;
        //     height: 1px;
        //     margin-left: 20px;
        //     background-color: $primaryLight; 
        // }
    }

    &__subtitle {
        display: block;
        font-size: $fs-sm;
        margin-bottom: $mb-3;
    }

    &__title,
    &__subtitle {
        text-align: center;
    }
}

@media screen and (min-width: 768px) {
    .section {
        padding: 5rem 0 4rem;
    }
}

@media screen and (min-width: 1024px) {
    .section {
        height: auto;
    }
}