@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

$bodyFont: 'Montserrat', sans-serif;
$fs-lg: 2rem; // large font size
$fs-h1: 1.5rem; // h1 font size
$fs-h2: 1.25rem; // h2 font size
$fs-h3: 1.125rem; // h3 font size
$fs-md: .938rem; // medium font size
$fs-sm: .813rem; // small font size
$fs-xs: .75rem; // extra small font size

$fw-md: 500; // medium font weight
$fw-sb: 600; // semi bold font size


/* Font size for large devices */
@media only screen and (min-width: 968px) {
    :root {
        $fs-lg: 3rem; 
        $fs-h1: 2.25rem;
        $fs-h2: 1.5rem;
        $fs-h3: 1.25rem;
        $fs-md: 1rem;
        $fs-sm: .875rem;
        $fs-xs: .813rem;
    }
}